import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Modal,
  Snackbar,
  IconButton,
  Container,
  Fab,
  Badge,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import AddIcon from "@mui/icons-material/Add";
import NavigationIcon from "@mui/icons-material/Navigation";
import { createTicket } from "../actions/ticketsLambda";
import SuccessModal from "../components/SuccessModal";
import Toast from "../components/Toast";
import SuccessTicketModal from "../components/SuccessTicketModal";
import { grey } from "@mui/material/colors";
import { checkEmail, getCustomerOrders } from "../actions/shopifyLambda";
import Loader from "../components/Loader";
import AddImage from "../components/AddImage";
import styled from "@emotion/styled";
import { uploadToDrive } from "../actions/googledrive";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: 10,
    bottom: 25,
    border: `2px solid white`,
    padding: "0 4px",
  },
}));

const TicketIssue = () => {
  const [customerId, setCustomerId] = useState(null);
  const [pageError, setPageError] = useState(false);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [selected, setSelected] = useState("");
  const [question, setQuestion] = useState("");
  const [reason, setReason] = useState("");
  const [orderID, setOrderID] = useState("");
  const [cancellationReason, setCancellationReason] = useState("");
  const [paymentGateway, setpaymentGateway] = useState([]);
  const [ticketId, setTicketId] = useState("");

  const [customerMail, setCustomerMail] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [mailStatus, setMailStatus] = useState(204);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  const [images, setImages] = useState([]);

  useEffect(() => {
    setLoading(true);
    const allParams = new URL(window.location).searchParams;
    const customer = allParams.get("customer");

    setCustomerId(customer);
    (async () => {
      const data = await getCustomerOrders(customer);
      if (data.success) {
        const orders = data.data.data.map((o) => ({
          order_number: o.order_number,
          payment_gateway_names: o.payment_gateway_names,
        }));
        setCustomerOrders(orders);
      } else {
        setPageError(true);
      }
      setLoading(false);
    })();
  }, []);
  const handleModalClose = () => setModalOpen(false);
  const handleImageModalClose = () => setImageModalOpen(false);
  const handleClose = () => setOpen(false);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let status = 204;
    if (!customerMail) {
      setLoading(false);
      setSnackBarMessage("Email not given");
      setOpen(true);
      return;
    }
    const mailCheck = await checkEmail(customerMail);
    if (mailCheck === 404) {
      setLoading(false);
      setEmailInvalid(true);
      setSnackBarMessage("Email Invalid");
      setOpen(true);
      return;
    }
    if (mailCheck === 500) {
      setMailStatus(500);
      status = 500;
    }
    if (!customerId) {
      setLoading(false);
      setSnackBarMessage("Customernot found, please reload and try again");
      setOpen(true);
      return;
    }
    if (!selected) {
      setLoading(false);
      setSnackBarMessage("Select Category");
      setOpen(true);
      return;
    }
    if (selected === "Order Cancellation" && !cancellationReason) {
      setLoading(false);
      setSnackBarMessage("Select Cancellation Reason");
      setOpen(true);
      return;
    }
    if (!question) {
      setLoading(false);
      setSnackBarMessage("Select Question");
      setOpen(true);
      return;
    }
    if (!orderID) {
      setLoading(false);
      setSnackBarMessage(
        "Order Id not selected, Select none if there is no order id"
      );
      setOpen(true);
      return;
    }
    if (selected === "Order Cancellation" && orderID === "None") {
      setLoading(false);
      setSnackBarMessage("Select Order for order related issues");
      setOpen(true);
      return;
    }

    if (selected === "Order Modification & Issues" && orderID === "None") {
      setLoading(false);
      setSnackBarMessage("Select Order for order related issues");
      setOpen(true);
      return;
    }

    if (!reason) {
      setLoading(false);
      setSnackBarMessage("Description not given");
      setOpen(true);
      return;
    }

    let payloadFiles = [];
    if (images.length > 0) {
      const uploadResponse = await uploadToDrive(images);
      if (uploadResponse.status === 200) {
        payloadFiles.push(...uploadResponse.data);
      } else {
        setLoading(false);
        setSnackBarMessage("Failed to upload files, try again");
        setOpen(true);
        return;
      }
    }
    const payload = {
      email: customerMail,
      mailCheckStatus: status,
      customer_id: customerId,
      category: selected,
      question: question,
      order_id: orderID,
      payment: customerOrders
        .filter((co) => String(co.order_number) === orderID)[0]
        .payment_gateway_names.includes("Cash on Delivery (COD)")
        ? "COD"
        : "Paid",
      files: payloadFiles,
      description: reason,
      cancellationReason,
    };
    if (
      question === "How do I cancel my order my COD order?" ||
      question === "How do I cancel my order my Prepaid order?"
    ) {
      payload["priority"] = "High";
    }
    const data = await createTicket(payload, customerMail);
    setLoading(false);
    if (data.success === true) {
      setTicketId(data.ticketId);
      setModalOpen(true);
      setSelected("");
      setQuestion("");
      setReason("");
      setCustomerMail("");
      setOrderID("");
    } else {
      setSnackBarMessage(data.data);
      setOpen(true);
    }
  };

  const handleCategoryChange = (e) => {
    setCancellationReason("");
    setSelected(e.target.value);
  };
  const returnExchange = [
    "My exchange request has been approved, When will the products be picked up?",
    "My exchange request has been rejected, What to do next?",
    "My returned products/product has been picked up. When will I receive my replacement order?",
    "Why is my pickup not yet done?",
  ];

  const deliveryOrder = [
    "How can I track my order?",
    "I want to return my order",
    "Is Cash on Delivery available?",
  ];

  const orderCancellation = [
    "How do I cancel my order my COD order?",
    "How do I cancel my order my Prepaid order?",
  ];

  const orderModification = [
    "How can I change my delivery address?",
    "I have ordered the wrong size/color. How can I change the size/color now?",
    "I'm unable to place my order",
    "I have already placed the COD but didn't receive any confirmation.",
    "Have received partial order, needed information about the unfulfilled products?",
  ];

  const paymentVoucher = [
    "How to apply voucher/giftcard?",
    "How do I pay for my order?",
    "What is the validity of the giftcard?",
  ];

  const orderCancellationReasons = [
    "Order Delayed",
    "Not Required Anymore",
    "Placed by Mistake/Other Reason",
  ];

  let category = null;
  let questions = null;

  if (selected === "Exchanges") {
    category = returnExchange;
  } else if (selected === "Delivery & Order Status") {
    category = deliveryOrder;
  } else if (selected === "Order Cancellation") {
    category = orderCancellation;
  } else if (selected === "Order Modification & Issues") {
    category = orderModification;
  } else if (selected === "Payment & Vouchers") {
    category = paymentVoucher;
  }

  if (category) {
    questions = category.map((q) => <MenuItem value={q}>{q}</MenuItem>);
  }

  return (
    <Box>
      {loading && (
        <div className="load-overlay">
          <Loader />
        </div>
      )}
      {pageError ? (
        <Container>
          <h1>Something Went Wrong, Please Try Again</h1>
        </Container>
      ) : (
        <Box>
          <section
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1>Raise an Issue</h1>

            <Box
              sx={{
                width: "80%",
                height: "610px",
                // width: { xl: 500, lg: 380 },
                // padding: "0 10px",
                // height: { xl: 700, lg: 550 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f7f7f7",
                borderRadius: "14px",
                border: "1px solid grey",
              }}
            >
              <img
                style={{
                  objectFit: "contain",
                  width: "90px",
                  position: "relative",
                  bottom: "20px",
                }}
                src="lblogo.jpeg"
                alt=""
              ></img>
              <form style={{ width: "70%" }} onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    onChange={(e) => setCustomerMail(e.target.value)}
                    id="outlined-basic"
                    label="E-mail"
                    variant="outlined"
                  />

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Category
                    </InputLabel>
                    <Select
                      id="demo-simple-select"
                      label="Select Category"
                      value={selected}
                      onChange={handleCategoryChange}
                    >
                      <MenuItem value={"Exchanges"}>Exchanges</MenuItem>
                      {/* <MenuItem value={"Delivery & Order Status"}>
                        Delivery & Order Status
                      </MenuItem> */}
                      <MenuItem value={"Order Cancellation"}>
                        Order Cancellation
                      </MenuItem>
                      <MenuItem value={"Order Modification & Issues"}>
                        Order Modification(Address/Size change) & Issues{" "}
                      </MenuItem>
                      {/* <MenuItem value={"Payment & Vouchers"}>
                        Payment & Vouchers
                      </MenuItem> */}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Related Questions
                    </InputLabel>
                    <Select
                      id="demo-simple-select"
                      label="Select Related Questions"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            whiteSpace: "pre-line",
                            "& .MuiMenuItem-root": {
                              whiteSpace: "pre-line",
                            },
                          },
                        },
                      }}
                    >
                      {questions}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Order ID
                    </InputLabel>
                    <Select
                      id="demo-simple-select"
                      label="Select Order"
                      value={orderID}
                      onChange={(e) => setOrderID(e.target.value)}
                    >
                      <MenuItem value={"None"}>None</MenuItem>
                      {customerOrders.map((o) => (
                        <MenuItem value={String(o.order_number)}>
                          {o.order_number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selected === "Order Cancellation" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Cancellation Reason
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        label="Select Order"
                        value={cancellationReason}
                        onChange={(e) => setCancellationReason(e.target.value)}
                      >
                        {orderCancellationReasons.map((o, i) => (
                          <MenuItem key={i} value={o}>
                            {o}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <FormControl fullWidth>
                    <Stack direction={"row"} alignItems="center" gap={2}>
                      <h2>Add Image</h2>
                      <Fab
                        onClick={() => setImageModalOpen(true)}
                        // variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="add"
                      >
                        <StyledBadge
                          badgeContent={images.length}
                          color="secondary"
                        >
                          <AddIcon />
                        </StyledBadge>
                      </Fab>
                    </Stack>
                  </FormControl>

                  <TextField
                    multiline
                    rows={3}
                    label="Describe Your Issue"
                    variant="filled"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                  <Button
                    type="submit"
                    style={{ backgroundColor: "rgb(185 26 158)" }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Stack>
              </form>
            </Box>
          </section>
          {/* {loading ? <Toast message={toastMessage} /> : ""} */}
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={snackbarMessage}
            action={action}
          />
          <Modal
            open={imageModalOpen}
            onClose={handleImageModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <AddImage
              setImages={setImages}
              handleClose={handleImageModalClose}
            />
          </Modal>
          {ticketId ? (
            <Modal
              open={modalOpen}
              onClose={handleModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <SuccessTicketModal ticketId={ticketId} />
            </Modal>
          ) : (
            ""
          )}
        </Box>
      )}
    </Box>
  );
};

export default TicketIssue;
