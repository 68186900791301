import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const ExchangeDisplay = ({ exchangeHistoryItems }) => {
  if (!exchangeHistoryItems) return;
  return (
    <>
      <h5>Here are the products you've asked to exchange so far:</h5>
      <TableContainer component={Paper}>
        <Table aria-label="exchange table">
          <TableHead>
            <TableRow>
              <TableCell>SL No</TableCell>
              <TableCell align="right">SKU</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exchangeHistoryItems?.map((row, index) => (
              <TableRow
                key={row.sku}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => {
                  window.location.href = `https://littleboxindia.com/search?q=${row.sku}`;
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="right">{row.sku}</TableCell>
                <TableCell align="right">Exchange Requested</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ExchangeDisplay;
